<template>
  <v-container>
    <div class="lk-main-content">
      <div :class="showSidebar ? '' : 'hide-side-bar'" class="lk-main-content__navbar">
        <v-btn class="lk-main-content__navbar_btn_hide" icon @click="showSidebar = !showSidebar">
          <v-icon color="primary" size="32">{{ showSidebar ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
        </v-btn>
        <transition name="slide">
          <ViewSidebar :show-side-bar="showSidebar"/>
        </transition>
      </div>
      <main class="lk-main-content__main">
        <slot></slot>
        <router-view></router-view>
      </main>
    </div>
  </v-container>
</template>
<script>
import {mapGetters} from 'vuex';
import Alert from "@/components/alerts/Alert";
import ViewSidebar from "@/views/private/sidebars/ViewSidebar";

export default {
  name: 'layoutDefault',
  // eslint-disable-next-line vue/no-unused-components
  components: {Alert, ViewSidebar},
  data() {
    return {
      showSidebar: true
    }
  },
  computed: {
    ...mapGetters('auth', {authData: 'authData'}),
    access() {
      return this.authData?.role.id === 14
    }
  },
}
</script>

<style lang="sass">
.lk-main
  .container
    margin-left: 0
    margin-right: 0
    max-width: unset

.lk-main-content
  display: flex

.lk-main-content__navbar
  min-width: 250px
  max-width: 250px
  margin-right: 20px
  border-right: 1px solid #cdd1d4
  padding-right: 10px
  box-sizing: border-box
  position: relative
  transition: all .5s ease
  
  &.hide-side-bar
    min-width: 50px
    max-width: 50px
    overflow-x: hidden
    
    .lk-main-content__navbar_btn_hide
      right: 10px
  
  .lk-main-content__navbar_btn_hide
    position: absolute
    right: 0
    top: 0
</style>