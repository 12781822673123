<template>
  <div class="nav_lk-sidebar">
    <div
      v-if="
        quotasOpen &&
        getUserRoles.find((el) => el === 'ORGANIZATION_ROLE') &&
        !getUserRoles.find((el) => el === 'ADMIN_ROLE')
      "
      class="nav-lk__section-inner">
      <div class="nav-lk__item">
        <router-link :to="{ path: '/' }" class="nav-lk__main-link">
          <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-indicators.svg" />
          <span v-if="showSideBar">Таблица</span>
        </router-link>
      </div>
    </div>
    <div
      v-if="
        plansOpen &&
        getUserRoles.find((el) => el === 'ORGANIZATION_ROLE') &&
        !getUserRoles.find((el) => el === 'ADMIN_ROLE')
      "
      class="nav-lk__section-inner">
      <div class="nav-lk__item">
        <router-link :to="{ name: 'LkMain' }" class="nav-lk__main-link">
          <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-indicators.svg" />
          <span v-if="showSideBar">Личный кабинет</span>
        </router-link>
      </div>
    </div>
    <!-- \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ -->
    <!--  REMOVE 16.03 this cabinets is rejected  <div
      v-if="getUserRoles.find(el => el === 'FOUNDER_ROLE' || el === 'REGION_ROLE')  && !getUserRoles.find(el => el === 'ADMIN_ROLE')"
      class="nav-lk__section">
      <div class="nav-lk__item" @click="infoFolderOpen = !infoFolderOpen">
        <router-link class="nav-lk__main-link" to="">
          <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-lk.svg">
          <span v-if="showSideBar">Предложения по целевой квоте</span>
        </router-link>
      </div>
    </div>
    <div
      v-if="getUserRoles.find(el => el === 'FOUNDER_ROLE' || el === 'REGION_ROLE') && infoFolderOpen && !getUserRoles.find(el => el === 'ADMIN_ROLE')"
      class="nav-lk__section-inner">
      <div class="nav-lk__item">
        <router-link class="nav-lk__main-link" :to="{path: '/contact'}">
          <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-indicators.svg">
          <span v-if="showSideBar">Контакт</span>
        </router-link>
      </div>
    </div>
    <div
      v-if="getUserRoles.find(el => el === 'FOUNDER_ROLE' || el === 'REGION_ROLE') && infoFolderOpen && get_check_filled_status"
      class="nav-lk__section-inner">
      <div class="nav-lk__item">
        <router-link class="nav-lk__main-link" :to="{path: '/dataGather'}">
          <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-indicators.svg">
          <span v-if="showSideBar">Сбор информации</span>
        </router-link>
      </div>
    </div>
    <div v-if="getUserRoles.find(el => el === 'FOUNDER_ROLE') && infoFolderOpen && get_check_filled_status"
         class="nav-lk__section-inner">
      <div class="nav-lk__item">
        <router-link class="nav-lk__main-link" :to="{path: '/subjectsData'}">
          <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-indicators.svg">
          <span v-if="showSideBar">Данные субъектов</span>
        </router-link>
      </div>
    </div>
    <div
      v-if="getUserRoles.find(el => el === 'FOUNDER_ROLE' || el === 'REGION_ROLE') && infoFolderOpen && get_check_filled_status"
      class="nav-lk__section-inner">
      <div class="nav-lk__item">
        <router-link class="nav-lk__main-link" :to="{path: '/approval'}">
          <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-indicators.svg">
          <span v-if="showSideBar && getUserRoles.find(el => el === 'REGION_ROLE')">Согласование с ФОИВ</span>
          <span v-if="showSideBar && getUserRoles.find(el => el === 'FOUNDER_ROLE')">Согласование с субъектом</span>
        </router-link>
      </div>
    </div>
    <div
      v-if="getUserRoles.find(el => el === 'FOUNDER_ROLE' || el === 'REGION_ROLE') && infoFolderOpen && get_check_filled_status"
      class="nav-lk__section-inner">
      <div class="nav-lk__item">
        <router-link class="nav-lk__main-link" :to="{path: '/print'}">
          <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-indicators.svg">
          <span v-if="showSideBar">Печать данных</span>
        </router-link>
      </div>
    </div>
    <div v-if="quotasOpen && getUserRoles.find(el => el === 'ADMIN_ROLE')" class="nav-lk__section-inner">
      <div class="nav-lk__item">
        <router-link to="/adminQuotaTable" class="nav-lk__main-link">
          <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-indicators.svg">
          <span v-if="showSideBar">Сбор данных</span>
        </router-link>
      </div>
    </div>
    <div
      v-if="getUserRoles.find(el => el === 'ADMIN_ROLE') && infoFolderOpen"
      class="nav-lk__section-inner">
      <div class="nav-lk__item">
        <router-link class="nav-lk__main-link" :to="{path: '/founders'}">
          <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-indicators.svg">
          <span v-if="showSideBar">ФОИВы</span>
        </router-link>
      </div>
    </div>
    <div
      v-if="getUserRoles.find(el => el === 'ADMIN_ROLE') && infoFolderOpen"
      class="nav-lk__section-inner">
      <div class="nav-lk__item">
        <router-link class="nav-lk__main-link" :to="{path: '/regions'}">
          <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-indicators.svg">
          <span v-if="showSideBar">Регионы</span>
        </router-link>
      </div>
    </div>
    <div
      v-if="getUserRoles.find(el => el === 'ADMIN_ROLE') && infoFolderOpen"
      class="nav-lk__section-inner">
      <div class="nav-lk__item">
        <router-link class="nav-lk__main-link" :to="{path: '/downloadAdmin'}">
          <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-indicators.svg">
          <span v-if="showSideBar">Выгрузка</span>
        </router-link>
      </div>
    </div>
    <div
      v-if="getUserRoles.find(el => el === 'FOUNDER_ROLE' || el === 'REGION_ROLE') && !getUserRoles.find(el => el === 'ADMIN_ROLE') && infoFolderOpen"
      class="nav-lk__section-inner">
      <div class="nav-lk__item">
        <router-link class="nav-lk__main-link" to="/sprContacts">
          <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-other.svg">
          <span v-if="showSideBar && getUserRoles.find(el => el === 'REGION_ROLE')">Справочник ФОИВов</span>
          <span v-if="showSideBar && getUserRoles.find(el => el === 'FOUNDER_ROLE')">Справочник регионов</span>
        </router-link>
      </div>
    </div>
    <div
      v-if="getUserRoles.find(el => el === 'FOUNDER_ROLE') && infoFolderOpen && !getUserRoles.find(el => el === 'ADMIN_ROLE')"
      class="nav-lk__section-inner">
      <a
        class="nav-lk__main-link"
        href="https://files.ined.ru/spck/foiv_manual.docx"
        style="margin-left: 56px;"
        target="_blank">
        Инструкция
      </a>
    </div>
    <div
      v-if="getUserRoles.find(el => el === 'REGION_ROLE') && infoFolderOpen && !getUserRoles.find(el => el === 'ADMIN_ROLE')"
      class="nav-lk__section-inner">
      <a
        class="nav-lk__main-link"
        href="https://files.ined.ru/spck/region_manual.docx"
        style="margin-left: 56px;"
        target="_blank">
        Инструкция
      </a>
    </div>
    &lt;!&ndash; \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ &ndash;&gt;
    <div
      v-if="getUserRoles.find(el => el === 'FOUNDER_ROLE' || el === 'REGION_ROLE') && !getUserRoles.find(el => el === 'ADMIN_ROLE') && plansOpen"
      class="nav-lk__section-inner">
      <div class="nav-lk__item">
        <div class="nav-lk__main-link" @click="getUploadedFile()">
          <div class="icon_nav-lk mr-4 d-flex align-center justify-center"
               style="border-radius: 50%; background-color: rgba(42,185,48,0.17);">
            <v-icon v-if="!uploadNow" color="#1F8B24" size="30" style="opacity: 1!important;">
              mdi-file-cog-outline
            </v-icon>
            <v-progress-circular
              v-if="uploadNow"
              color="#eb722e"
              indeterminate
              size="25"
              width="2"
            ></v-progress-circular>
          </div>
          <span v-if="showSideBar">Инструкция</span>
        </div>
      </div>
    </div>-->

    <!--    <div v-if="getUserRoles.find(el => el === 'ADMIN_ROLE')" class="nav-lk__section">-->
    <!--      <div class="nav-lk__item">-->
    <!--        <router-link :to="{name: 'LkMain'}" class="nav-lk__main-link">-->
    <!--          <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-other.svg">-->
    <!--          <span>Выйти из организации</span>-->
    <!--        </router-link>-->
    <!--      </div>-->
    <!--    </div>-->

    <!-- \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ детализация квоты -->

    <!--    <div v-if="getUserRoles.find(el => el === 'ADMIN_ROLE')" class="nav-lk__section">-->
    <!--      <div class="nav-lk__item" @click="quotasOpen = !quotasOpen">-->
    <!--        <router-link class="nav-lk__main-link" to="">-->
    <!--          <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-lk.svg">-->
    <!--          <span v-if="showSideBar">Детализация квоты</span>-->
    <!--        </router-link>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div v-if="quotasOpen && getUserRoles.find(el => el === 'ADMIN_ROLE')" class="nav-lk__section-inner">-->
    <!--      <div class="nav-lk__item">-->
    <!--        <router-link :to="{path: '/organizations', }" class="nav-lk__main-link">-->
    <!--          <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-indicators.svg">-->
    <!--          <span v-if="showSideBar">Организации</span>-->
    <!--        </router-link>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div v-if="quotasOpen && getUserRoles.find(el => el === 'ADMIN_ROLE')" class="nav-lk__section-inner">-->
    <!--      <div class="nav-lk__item">-->
    <!--        <router-link :to="{path: '/adminQuotaFounders', }" class="nav-lk__main-link">-->
    <!--          <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-indicators.svg">-->
    <!--          <span v-if="showSideBar">ФОИВы</span>-->
    <!--        </router-link>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div v-if="quotasOpen && getUserRoles.find(el => el === 'ADMIN_ROLE')" class="nav-lk__section-inner">-->
    <!--      <div class="nav-lk__item">-->
    <!--        <router-link :to="{path: '/adminQuotaRegions', }" class="nav-lk__main-link">-->
    <!--          <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-indicators.svg">-->
    <!--          <span v-if="showSideBar">РОИВы</span>-->
    <!--        </router-link>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    &lt;!&ndash; \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ userProfile === 4 &ndash;&gt;-->
    <!--        <div-->
    <!--          v-if="getUserRoles.find(el => el === 'FOUNDER_ROLE' || el === 'REGION_ROLE') && !getUserRoles.find(el => el === 'ADMIN_ROLE')"-->
    <!--          class="nav-lk__section">-->
    <!--          <div class="nav-lk__item" @click="quotasOpen = !quotasOpen">-->
    <!--            <router-link class="nav-lk__main-link" to="">-->
    <!--              <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-lk.svg">-->
    <!--              <span v-if="showSideBar">Детализация квоты</span>-->
    <!--            </router-link>-->
    <!--          </div>-->
    <!--        </div>-->
    <template v-if="sborType === 'SBOR_APPROVALS'">
      <template v-if="getUserRoles.find((el) => el === 'ADMIN_ROLE')">
        <div v-for="route in adminRoutes" :key="route.text" class="nav-lk__section">
          <div class="nav-lk__item" v-if="!route.link">
            <router-link class="nav-lk__main-link" :to="route.path">
              <img
                alt=""
                class="nav-lk__icon icon_nav-lk"
                :src="require(`@/assets/img/${route.img}.svg`)" />
              <span v-if="showSideBar">{{ route.text }}</span>
            </router-link>
          </div>
        </div>
      </template>

      <template
        v-if="
          getUserRoles.find((el) => el === 'FOUNDER_ROLE' || el === 'REGION_ROLE') &&
          !getUserRoles.find((el) => el === 'ADMIN_ROLE')
        ">
        <div v-for="route in approvalsRoutes" :key="route.text" class="nav-lk__section">
          <div class="nav-lk__item" v-if="!route.link">
            <router-link class="nav-lk__main-link" :to="route.path">
              <img
                alt=""
                class="nav-lk__icon icon_nav-lk"
                :src="require(`@/assets/img/${route.img}.svg`)" />
              <span v-if="showSideBar">{{ route.text }}</span>
            </router-link>
          </div>
          <div class="nav-lk__item" v-else>
            <a class="nav-lk__main-link" target="_blank" :href="route.path">
              <img
                alt=""
                class="nav-lk__icon icon_nav-lk"
                :src="require(`@/assets/img/${route.img}.svg`)" />
              <span v-if="showSideBar">{{ route.text }}</span>
            </a>
          </div>
        </div>
      </template>
    </template>
    <template v-if="sborType === 'SBOR_SUGGESTIONS'">
      <template v-if="getUserRoles.find((el) => el === 'ADMIN_ROLE')">
        <div v-for="route in adminSuggestionsRoutes" :key="route.text" class="nav-lk__section">
          <div class="nav-lk__item" v-if="!route.link">
            <router-link class="nav-lk__main-link" :to="route.path">
              <img
                alt=""
                class="nav-lk__icon icon_nav-lk"
                :src="require(`@/assets/img/${route.img}.svg`)" />
              <span v-if="showSideBar">{{ route.text }}</span>
            </router-link>
          </div>
        </div>
      </template>

      <template
        v-if="
          getUserRoles.find((el) => el === 'FOUNDER_ROLE' || el === 'REGION_ROLE') &&
          !getUserRoles.find((el) => el === 'ADMIN_ROLE')
        ">
        <div v-for="route in filteredRoutes" :key="route.text" class="nav-lk__section">
          <div class="nav-lk__item" v-if="!route.link">
            <router-link class="nav-lk__main-link" :to="route.path">
              <img
                alt=""
                class="nav-lk__icon icon_nav-lk"
                :src="require(`@/assets/img/${route.img}.svg`)" />
              <span v-if="showSideBar">{{ route.text }}</span>
            </router-link>
          </div>
          <div class="nav-lk__item" v-else>
            <a class="nav-lk__main-link" target="_blank" :href="route.path">
              <img
                alt=""
                class="nav-lk__icon icon_nav-lk"
                :src="require(`@/assets/img/${route.img}.svg`)" />
              <span v-if="showSideBar">{{ route.text }}</span>
            </a>
          </div>
        </div>
      </template>
    </template>
    <!--        <div-->
    <!--          v-if="quotasOpen && getUserRoles.find(el => el === 'FOUNDER_ROLE' || el === 'REGION_ROLE') && !getUserRoles.find(el => el === 'ADMIN_ROLE')"-->
    <!--          class="nav-lk__section-inner">-->
    <!--          <div class="nav-lk__item">-->
    <!--            <router-link :to="{path: '/', }" class="nav-lk__main-link">-->
    <!--              <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-indicators.svg">-->
    <!--              <span v-if="showSideBar">Контакт</span>-->
    <!--            </router-link>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div-->
    <!--          v-if="quotasOpen && getUserRoles.find(el => el === 'FOUNDER_ROLE' || el === 'REGION_ROLE') && !getUserRoles.find(el => el === 'ADMIN_ROLE')"-->
    <!--          class="nav-lk__section-inner">-->
    <!--          <div class="nav-lk__item">-->
    <!--            <router-link :to="{path: '/quotaTable', }" class="nav-lk__main-link">-->
    <!--              <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-indicators.svg">-->
    <!--              <span v-if="showSideBar">Ввод данных</span>-->
    <!--            </router-link>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div-->
    <!--          v-if="quotasOpen && getUserRoles.find(el => el === 'FOUNDER_ROLE' || el === 'REGION_ROLE') && !getUserRoles.find(el => el === 'ADMIN_ROLE')"-->
    <!--          class="nav-lk__section-inner">-->
    <!--          <div class="nav-lk__item">-->
    <!--            <router-link :to="{path: '/quotaPrint', }" class="nav-lk__main-link">-->
    <!--              <img alt="" class="nav-lk__icon icon_nav-lk" src="@/assets/img/icon-nav-other.svg">-->
    <!--              <span v-if="showSideBar">Печать данных</span>-->
    <!--            </router-link>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div-->
    <!--          v-if="!getUserRoles.find(el => el === 'ADMIN_ROLE' || el === 'ORGANIZATION_ROLE')" class="nav-lk__section-inner">-->
    <!--          <a-->
    <!--            class="nav-lk__main-link"-->
    <!--            href="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_fbb4346e8152c3480a1005d0f35cdec5.pdf"-->
    <!--            style="margin-left: 56px;"-->
    <!--            target="_blank">-->
    <!--            Инструкция-->
    <!--          </a>-->
    <!--        </div>-->

    <!-- \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ детализация квоты конец-->
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import request from '@/services/request';
  import saveFile from '@/utils/saveFile';
  import axios from 'axios';

  export default {
    name: 'sideBar',
    props: {
      showSideBar: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      uploadNow: false,
      plansOpen: false,
      quotasOpen: true,
      infoFolderOpen: true,
      sborType: '',
      adminRoutesDefault: [
        {
          text: 'Сбор данных',
          path: '/adminQuotaTable',
          img: 'icon-nav-indicators',
        },
        {
          text: 'ФОИВы квота',
          path: '/adminQuotaFounders',
          img: 'icon-nav-indicators',
        },
        {
          text: 'Регионы квота',
          path: '/adminQuotaRegions',
          img: 'icon-nav-indicators',
        },
        {
          text: 'Выгрузка',
          path: '/downloadAdmin',
          img: 'icon-nav-indicators',
        },
      ],
      adminSuggestionsRoutes: [
        {
          text: 'ФОИВы',
          path: '/founders',
          img: 'icon-nav-indicators',
        },
        {
          text: 'Регионы',
          path: '/regions',
          img: 'icon-nav-indicators',
        },
        {
          text: 'Выгрузка',
          path: '/downloadAdmin',
          img: 'icon-nav-indicators',
        },
      ],
      routes: [
        {
          text: 'Предложения по квоте',
          path: '',
          img: 'icon-nav-lk',
          roles: ['FOUNDER_ROLE', 'REGION_ROLE'],
          excludeRoles: ['ADMIN_ROLE'],
        },
        {
          text: 'Контакт',
          path: '/',
          img: 'icon-nav-indicators',
          roles: ['FOUNDER_ROLE', 'REGION_ROLE'],
          excludeRoles: ['ADMIN_ROLE'],
        },
        {
          text: 'Сбор информации',
          path: '/dataGather',
          img: 'icon-nav-indicators',
          roles: ['FOUNDER_ROLE', 'REGION_ROLE'],
          excludeRoles: ['ADMIN_ROLE'],
        },
        {
          text: 'Данные субъектов',
          path: '/subjectsData',
          img: 'icon-nav-indicators',
          roles: ['FOUNDER_ROLE'],
          excludeRoles: ['ADMIN_ROLE'],
        },
        {
          text: 'Согласование с субъектом',
          path: '/approval',
          img: 'icon-nav-indicators',
          roles: ['FOUNDER_ROLE'],
          excludeRoles: ['ADMIN_ROLE'],
        },
        {
          text: 'Согласование с ФОИВ',
          path: '/approval',
          img: 'icon-nav-indicators',
          roles: ['REGION_ROLE'],
          excludeRoles: ['ADMIN_ROLE'],
        },
        {
          text: 'Печать данных',
          path: '/print',
          img: 'icon-nav-other',
          roles: ['FOUNDER_ROLE', 'REGION_ROLE'],
          excludeRoles: ['ADMIN_ROLE'],
        },
        {
          text: 'Справочник ФОИВ',
          path: '/sprContacts',
          img: 'icon-nav-indicators',
          roles: ['REGION_ROLE'],
          excludeRoles: ['ADMIN_ROLE'],
        },
        {
          text: 'Справочник регионов',
          path: '/sprContacts',
          img: 'icon-nav-indicators',
          roles: ['FOUNDER_ROLE'],
          excludeRoles: ['ADMIN_ROLE'],
        },
        {
          text: 'Инструкция',
          link: true,
          path: 'https://files.ined.ru/spck/instruction_for_foiv.pdf',
          img: 'icon-nav-link',
          roles: ['FOUNDER_ROLE'],
          excludeRoles: ['ADMIN_ROLE', 'ORGANIZATION_ROLE'],
        },
        {
          text: 'Инструкция',
          link: true,
          path: 'https://files.ined.ru/spck/instruction_for_subjects.pdf',
          img: 'icon-nav-link',
          roles: ['REGION_ROLE'],
          excludeRoles: ['ADMIN_ROLE', 'ORGANIZATION_ROLE'],
        },
      ],
      approvalsRoutes: [
        {
          text: 'Детализация квоты',
          path: '',
          img: 'icon-nav-lk',
          roles: ['FOUNDER_ROLE', 'REGION_ROLE'],
          excludeRoles: ['ADMIN_ROLE'],
        },
        {
          text: 'Контакт',
          path: '/',
          img: 'icon-nav-indicators',
          roles: ['FOUNDER_ROLE', 'REGION_ROLE'],
          excludeRoles: ['ADMIN_ROLE'],
        },
        {
          text: 'Ввод данных',
          path: '/quotaTable',
          img: 'icon-nav-indicators',
          roles: ['FOUNDER_ROLE', 'REGION_ROLE'],
          excludeRoles: ['ADMIN_ROLE'],
        },
        {
          text: 'Печать данных',
          path: '/quotaPrint',
          img: 'icon-nav-other',
          roles: ['FOUNDER_ROLE', 'REGION_ROLE'],
          excludeRoles: ['ADMIN_ROLE'],
        },
        {
          text: 'Просмотр старых файлов',
          path: '/quotaPrintPrevYears',
          img: 'icon-nav-other',
          roles: ['FOUNDER_ROLE', 'REGION_ROLE'],
          excludeRoles: ['ADMIN_ROLE'],
        },
        {
          text: 'Инструкция',
          link: true,
          path: 'https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_fbb4346e8152c3480a1005d0f35cdec5.pdf',
          img: 'icon-nav-link',
          roles: ['FOUNDER_ROLE', 'REGION_ROLE'],
          excludeRoles: ['ADMIN_ROLE', 'ORGANIZATION_ROLE'],
        },
      ],
    }),
    computed: {
      // currentPath() {
      //   return this.$route.path
      // },

      ...mapGetters('checkFilled', { get_check_filled_status: 'get_check_filled_status' }),
      ...mapGetters('keycloak', {
        getAuthData: 'getAuthData',
        getUser: 'getUser',
        getUserRoles: 'getUserRoles',
        isAdmin: 'isAdmin',
      }),
      // filteredRoutes() {
      //   return this.routes.filter((el) => {
      //     let accept = false;
      //     const roles = el.roles;
      //     const excludeRoles = el.excludeRoles ?? [];
      //     for (const role of roles) {
      //       if (this.getUserRoles && this.getUserRoles.includes(role)) accept = true;
      //     }
      //     for (const role of excludeRoles) {
      //       if (this.getUserRoles && this.getUserRoles.includes(role)) accept = false;
      //     }
      //     return accept;
      //   });
      // },
      filteredRoutes() {
        return this.routes.filter((el) =>
          el.roles.some((role) => this.getUserRoles.includes(role)),
        );
      },

      adminRoutes() {
        return this.isAdmin ? this.adminRoutesDefault : [];
      },
      // getQuery() {
      //   if (this.$route.query?.regionID) {
      //     return {regionID: this.$route.query?.regionID};
      //   }
      //   if (this.$route.query.founderID) {
      //     return {founderID: this.$route.query.founderID};
      //   }
      //   return null;
      // },
    },

    mounted() {
      this.getSborType();
      if (
        !this.getUserRoles.find((el) => el === 'ADMIN_ROLE') &&
        !this.getUserRoles.find((el) => el === 'ORGANIZATION_ROLE')
      ) {
        this.getContacts();
        this.getMainContact();
      }
    },

    methods: {
      ...mapActions('checkFilled', { fetchCheckFilled: 'fetchCheckFilled' }),
      ...mapActions('notifications', { addNotification: 'addNotification' }),

      async getUploadedFile() {
        this.uploadNow = true;
        let response = null;
        if (this.getUserRoles.find((el) => el === '9')) {
          response = await request({
            endpoint: `/api/file/scan/download?fileId=4e6b48e7-2d81-4d3b-a371-2dd1ecb7ab7e`,
            method: 'GET',
          });
        }
        if (this.getUserRoles.find((el) => el === 'FOUNDER_ROLE')) {
          response = await request({
            endpoint: `/api/file/scan/download?fileId=237d6829-0ee1-4ca2-93ec-b062b71ae24e`,
            method: 'GET',
          });
        }
        this.uploadNow = false;
        if (response) {
          saveFile(response.data, false);
        }
      },

      async getMainContact() {
        let uri = `/api/person/official/getAllFoivId?founderId=${this.getUser.founderID}`;
        if (this.getUserRoles.find((el) => el === 'REGION_ROLE')) {
          uri = `/api/person/official/getAllRegionId?regionId=${this.getUser.regionID}`;
        }
        const res = await request({
          endpoint: uri,
          method: 'get',
          token: `${this.getAuthData.token}`,
        });
        if (res.status === 200) {
          if (res.data?.personId > 0) {
            const bufRes = res.data;
            for (const key of Object.keys(res.data)) {
              bufRes[key] = bufRes[key] === null ? '' : bufRes[key];
            }
            this.mainContact = bufRes;
            await this.fetchCheckFilled({ mainContact: this.mainContact, contacts: this.contacts });
          }
        } else {
          if (res.status !== 404)
            await this.addNotification({
              notificationStatus: 'error',
              notificationMessage: 'Ошибка получения данных ответственных исполнителей',
              timeout: 5000,
            });
        }
      },

      async getContacts() {
        let uri = `/api/person/direct/getAllFoivId?founderId=${this.getUser.founderID}`;
        if (this.getUserRoles.find((el) => el === 'REGION_ROLE')) {
          uri = `/api/person/direct/getAllRegionId?regionId=${this.getUser.regionID}`;
        }
        const res = await request({
          endpoint: uri,
          method: 'get',
          token: `${this.getAuthData.token}`,
        });
        if (res.status === 200) {
          if (res.data.length > 0) {
            this.contacts = res.data.map((el) => {
              for (const key of Object.keys(el)) {
                el[key] = el[key] === null ? '' : el[key];
              }
              return el;
            });
            await this.fetchCheckFilled({ mainContact: this.mainContact, contacts: this.contacts });
          }
        } else {
          if (res.status !== 404)
            await this.addNotification({
              notificationStatus: 'error',
              notificationMessage: 'Ошибка получения данных ответственных исполнителей',
              timeout: 5000,
            });
        }
      },

      async getSborType() {
        try {
          const response = await axios.get('/api/admin/param?param=SBOR_TYPE');
          if (response.data.paramValue === 'SBOR_SUGGESTIONS') {
            this.sborType = 'SBOR_SUGGESTIONS';
          } else if (response.data.paramValue === 'SBOR_APPROVALS') {
            this.sborType = 'SBOR_APPROVALS';
          } else {
            throw new Error('Неправильный ответ от сервера');
          }
        } catch (e) {
          console.log('Ошибка при получении SBOR_TYPE:', e);
          throw e;
        }
        console.log(this.sborType);
      },
    },
  };
</script>
<style lang="sass">

  .nav_lk-sidebar
    display: flex
    flex-direction: column
    height: 100%
    margin-top: 38px

    .nav-link_lk-sidebar:hover
      color: #eb8e1c

    .nav-link_lk-sidebar.nav-link_lk-sidebar_prime
      color: #646262
      font-size: 14px
      margin-left: 5px

    .nav-item_lk-sidebar
      display: flex
      align-items: flex-start
      margin-left: 20px

    .nav-lk__section
      margin-bottom: 25px

    .nav-lk__section-inner
      margin-left: 15px
      margin-bottom: 25px

    .nav-lk__item
      display: flex

    .icon_nav-lk
      width: 40px
      height: 40px

    .nav-lk__icon
      margin-right: 16px

    .nav-lk__main-link
      color: #00599B
      font-size: 18px

    .nav-lk__under-link
      color: #426075 !important
      line-height: 17.6px
      font-size: 16px

    .icon_under-items
      height: 12px
      width: 6px

    .nav-lk__under-icon
      margin-right: 10px

    .nav-lk__main-link
      height: 40px
      display: flex
      align-items: center

    .nav-lk__under-items
      padding-left: 56px
</style>
