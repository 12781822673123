var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav_lk-sidebar"},[(
      _vm.quotasOpen &&
      _vm.getUserRoles.find((el) => el === 'ORGANIZATION_ROLE') &&
      !_vm.getUserRoles.find((el) => el === 'ADMIN_ROLE')
    )?_c('div',{staticClass:"nav-lk__section-inner"},[_c('div',{staticClass:"nav-lk__item"},[_c('router-link',{staticClass:"nav-lk__main-link",attrs:{"to":{ path: '/' }}},[_c('img',{staticClass:"nav-lk__icon icon_nav-lk",attrs:{"alt":"","src":require("@/assets/img/icon-nav-indicators.svg")}}),(_vm.showSideBar)?_c('span',[_vm._v("Таблица")]):_vm._e()])],1)]):_vm._e(),(
      _vm.plansOpen &&
      _vm.getUserRoles.find((el) => el === 'ORGANIZATION_ROLE') &&
      !_vm.getUserRoles.find((el) => el === 'ADMIN_ROLE')
    )?_c('div',{staticClass:"nav-lk__section-inner"},[_c('div',{staticClass:"nav-lk__item"},[_c('router-link',{staticClass:"nav-lk__main-link",attrs:{"to":{ name: 'LkMain' }}},[_c('img',{staticClass:"nav-lk__icon icon_nav-lk",attrs:{"alt":"","src":require("@/assets/img/icon-nav-indicators.svg")}}),(_vm.showSideBar)?_c('span',[_vm._v("Личный кабинет")]):_vm._e()])],1)]):_vm._e(),(_vm.sborType === 'SBOR_APPROVALS')?[(_vm.getUserRoles.find((el) => el === 'ADMIN_ROLE'))?_vm._l((_vm.adminRoutes),function(route){return _c('div',{key:route.text,staticClass:"nav-lk__section"},[(!route.link)?_c('div',{staticClass:"nav-lk__item"},[_c('router-link',{staticClass:"nav-lk__main-link",attrs:{"to":route.path}},[_c('img',{staticClass:"nav-lk__icon icon_nav-lk",attrs:{"alt":"","src":require(`@/assets/img/${route.img}.svg`)}}),(_vm.showSideBar)?_c('span',[_vm._v(_vm._s(route.text))]):_vm._e()])],1):_vm._e()])}):_vm._e(),(
        _vm.getUserRoles.find((el) => el === 'FOUNDER_ROLE' || el === 'REGION_ROLE') &&
        !_vm.getUserRoles.find((el) => el === 'ADMIN_ROLE')
      )?_vm._l((_vm.approvalsRoutes),function(route){return _c('div',{key:route.text,staticClass:"nav-lk__section"},[(!route.link)?_c('div',{staticClass:"nav-lk__item"},[_c('router-link',{staticClass:"nav-lk__main-link",attrs:{"to":route.path}},[_c('img',{staticClass:"nav-lk__icon icon_nav-lk",attrs:{"alt":"","src":require(`@/assets/img/${route.img}.svg`)}}),(_vm.showSideBar)?_c('span',[_vm._v(_vm._s(route.text))]):_vm._e()])],1):_c('div',{staticClass:"nav-lk__item"},[_c('a',{staticClass:"nav-lk__main-link",attrs:{"target":"_blank","href":route.path}},[_c('img',{staticClass:"nav-lk__icon icon_nav-lk",attrs:{"alt":"","src":require(`@/assets/img/${route.img}.svg`)}}),(_vm.showSideBar)?_c('span',[_vm._v(_vm._s(route.text))]):_vm._e()])])])}):_vm._e()]:_vm._e(),(_vm.sborType === 'SBOR_SUGGESTIONS')?[(_vm.getUserRoles.find((el) => el === 'ADMIN_ROLE'))?_vm._l((_vm.adminSuggestionsRoutes),function(route){return _c('div',{key:route.text,staticClass:"nav-lk__section"},[(!route.link)?_c('div',{staticClass:"nav-lk__item"},[_c('router-link',{staticClass:"nav-lk__main-link",attrs:{"to":route.path}},[_c('img',{staticClass:"nav-lk__icon icon_nav-lk",attrs:{"alt":"","src":require(`@/assets/img/${route.img}.svg`)}}),(_vm.showSideBar)?_c('span',[_vm._v(_vm._s(route.text))]):_vm._e()])],1):_vm._e()])}):_vm._e(),(
        _vm.getUserRoles.find((el) => el === 'FOUNDER_ROLE' || el === 'REGION_ROLE') &&
        !_vm.getUserRoles.find((el) => el === 'ADMIN_ROLE')
      )?_vm._l((_vm.filteredRoutes),function(route){return _c('div',{key:route.text,staticClass:"nav-lk__section"},[(!route.link)?_c('div',{staticClass:"nav-lk__item"},[_c('router-link',{staticClass:"nav-lk__main-link",attrs:{"to":route.path}},[_c('img',{staticClass:"nav-lk__icon icon_nav-lk",attrs:{"alt":"","src":require(`@/assets/img/${route.img}.svg`)}}),(_vm.showSideBar)?_c('span',[_vm._v(_vm._s(route.text))]):_vm._e()])],1):_c('div',{staticClass:"nav-lk__item"},[_c('a',{staticClass:"nav-lk__main-link",attrs:{"target":"_blank","href":route.path}},[_c('img',{staticClass:"nav-lk__icon icon_nav-lk",attrs:{"alt":"","src":require(`@/assets/img/${route.img}.svg`)}}),(_vm.showSideBar)?_c('span',[_vm._v(_vm._s(route.text))]):_vm._e()])])])}):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }