<template>
  <div class="lk">
    <LayoutFrontTopbar/>
    <!-- Контентная часть -->
    <section class="lk-main">
      <component :is="layout">
<!--        <v-container></v-container>-->
      </component>
    </section>
  </div>
</template>

<script>
import LayoutFrontTopbar from "@/layout/LayoutFront/LayoutFrontTopbar";
import LayoutLkContentDefault from "@/layout/LayoutLk/layoutLkContent/LayoutLkContentDefault";

export default {
  components: {
    LayoutFrontTopbar,
    LayoutLkContentDefault,
  },
  
  data() {
    return {}
  },
  computed: {
    layout() {
      return this.$route.meta.layout ? this.$route.meta.layout : 'LayoutLkContentDefault';
    },
  },
}
</script>


<style lang="sass">
.lk
  height: 100%
  background: #f8f9fb
</style>